import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../hooks/auth';

interface RuleModalProps {
  isEditable: boolean;
  materials: any[];
  onSave: (materials: any[]) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  materialPrizes: any
}

const SpecialsMaterialsModal: React.FC<RuleModalProps> = ({isEditable, isOpen, setIsOpen, materials, onSave, materialPrizes }) => {
  const [changedPrizes, setChangedPrizes] = useState<any>(materialPrizes);
  const [isPrizeTooLow, setIsPrizeTooLow] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    setChangedPrizes(materialPrizes);
  }, [materialPrizes])

  useEffect(() => {
    if(!user.isAdmin) setIsPrizeTooLow(!Object.values(changedPrizes).every((x:any)=>x.every((y:any)=>!y || y>=25)));
  }, [changedPrizes])

  const save = () => {
    onSave(changedPrizes);
    setIsOpen(false)
  };

  const changePrice = async (type: number, val: string, material: any) => {
    const newPrizes = { ...changedPrizes };
    const priceArray = getNewPrizes(type, val, material);
    newPrizes[material.key] = priceArray;
    if (material?.parent) {
      if(newPrizes?.[`group-${material.parent}`]) newPrizes[`group-${material.parent}`][type] = null;
    }
    if (material?.children?.length > 0) {
      material.children.forEach((material: any) => {
        const priceArray = getNewPrizes(type, val, material);
        newPrizes[material.key] = priceArray;
      })
    }
    setChangedPrizes(newPrizes);
  }
  const getNewPrizes = (type: number, val: string, material: any) => {
    const { key, prizes } = material;
    const priceArray = changedPrizes[key] ? [...changedPrizes[key]] : [...(prizes || [])]
    priceArray[type] = val;
    return priceArray;
  }
  const deletePrize = (row: any) => {
    const newPrizes = { ...changedPrizes };
    delete newPrizes[row.key];
    if (row?.parent) {
      delete newPrizes[`group-${row.parent}`];
    }
    if (row?.children) {
      for (const child of row.children) {
        delete newPrizes[child.key];
      }
    }
    setChangedPrizes(newPrizes);
  }
  const getColor = (material: any) => {
    if (changedPrizes[material.key]) return 'red';
    if (material?.children?.some((x: any) => changedPrizes[x.key])) return 'red';
    return '';
  }
  const getCategoryPrize = (category:any, index: number) => {
    const firstChildrenPrize = changedPrizes?.[category.children[0].key]?.[index];
    return category.children.every((material:any)=>changedPrizes?.[material.key]?.[index]===firstChildrenPrize) ?
    firstChildrenPrize : 'Изменено';
  }

  return (
    <Modal
      width={850}
      title={'Выбор материалов'}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={isEditable ? [
        isPrizeTooLow ? <span style={{color: "red", marginRight: 10}}>Минимальная цена должна быть не ниже 25</span> : null,
        <Button key="submit" type="primary" onClick={save} disabled={isPrizeTooLow}>
          Сохранить
        </Button>,
      ] : []}
    >
      <div>Выбрано материалов для акции: {Object.keys(changedPrizes).filter(x => x.startsWith('element')).length}</div>
      <Table
        columns={[
          {
            title: '',
            dataIndex: 'title',
            render: (val, row) => {
              return <div>
                <span style={{ color: getColor(row) }}>{val}</span>
                {getColor(row) && isEditable && <span onClick={() => deletePrize(row)} style={{ marginLeft: 10, position: 'relative', top: 1, cursor: 'pointer' }}><CloseOutlined /></span>}
              </div>
            }
          },
          {
            title: 'Первый успех',
            dataIndex: 'prizes',
            width: 150,
            render: (val, row) => {
              const value = changedPrizes[row.key]?.[0] >= 0 ? changedPrizes[row.key]?.[0] : val?.[0]
              const defaultVal = row?.children?.length && !isEditable ? getCategoryPrize(row, 0) : '';
              return isEditable ? <Input
                defaultValue={defaultVal}
                value={value}
                onChange={e => changePrice(0, e.target.value, row)}
                style={{ border: changedPrizes?.[row.key]?.[0] && changedPrizes[row.key][0] < 25 ? "2px solid red" : "" }}
              /> : value || defaultVal;
            }
          },
          {
            title: 'Второй успех',
            dataIndex: 'prizes',
            width: 150,
            render: (val, row) => {
              const value = changedPrizes[row.key]?.[1] >= 0 ? changedPrizes[row.key]?.[1] : val?.[1]
              const defaultVal = row?.children?.length && !isEditable ? getCategoryPrize(row, 1) : '';
              return isEditable ? <Input
                defaultValue={defaultVal}
                value={changedPrizes[row.key]?.[1] ?? val?.[1]}
                onChange={e => changePrice(1, e.target.value, row)}
                style={{ border: changedPrizes?.[row.key]?.[1] && changedPrizes[row.key][1] < 25 ? "2px solid red" : "" }}
              /> : value || defaultVal;
            }
          },
          {
            title: 'Третий успех и далее',
            dataIndex: 'prizes',
            width: 150,
            render: (val, row) => {
              const value = changedPrizes[row.key]?.[2] >= 0 ? changedPrizes[row.key]?.[2] : val?.[2]
              const defaultVal = row?.children?.length && !isEditable ? getCategoryPrize(row, 2) : '';
              return isEditable ? <Input
                defaultValue={defaultVal}
                value={changedPrizes[row.key]?.[2] ?? val?.[2]}
                onChange={e => changePrice(2, e.target.value, row)}
                style={{ border: changedPrizes?.[row.key]?.[2] && changedPrizes[row.key][2] < 25 ? "2px solid red" : "" }}
              /> : value || defaultVal;
            }
          },
        ]}
        dataSource={materials}
      />
    </Modal>
  );
};

export default SpecialsMaterialsModal;