import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Table, Spin, Select, Modal } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useAuth } from '../../../hooks/auth';
import SpecialModal from './SpecialModal/SpecialModal';
import { listSpecial, deleteSpecial } from '../../../services/specialApi';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDebounce } from '../../../hooks/useDebounce';
import { getAllStates } from '../../../services/adminApi';
import dayjs from 'dayjs';

interface DataType {
  id: number;
  title: string;
  start: string;
  end: string;
  users: any[];
  materials: any[];
  status: number;
  stateId: number;
}

const Specials: React.FC = () => {
  const [specials, setSpecials] = useState<any[]>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [specialData, setSpecialData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [stateFilter, setStateFilter] = useState<any>({ id: 0, name: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [countRows, setCountRows] = useState(10);
  const { user } = useAuth();
  const pageSize = 10;

  useEffect(() => { getData() }, [currentPage, searchFilter, stateFilter]);

  const getData = () => {
    setIsLoading(true);
    listSpecial(currentPage, searchFilter, stateFilter.id).then((data) => {
      setSpecials(data.rows);
      setCountRows(data.count);
      setIsLoading(false);
    });
    if (user.isAdmin || [3].includes(user.role)) getAllStates().then(setStateList);
  };

  const handleDelete = async (id: number) => {
    Modal.confirm({
      title: 'Удалить акцию?',
      okText: 'Да',
      cancelText: 'Нет',
      async onOk() {
        await deleteSpecial(id);
        setSpecials(specials.filter((x: any) => x.id !== id))
      },
    });
  };

  const handleEdit = (special: any) => {
    setSpecialData(special);
  };

  const statusList = [{ val: 'На согласовании', color: '' }, { val: 'Согласовано', color: 'green' }, { val: 'Отказ', color: 'red' }, { val: 'Завершена', color: '' }];

  const columns: ColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'title',
    },
    ...(user.isAdmin || [3].includes(user.role) ? [{
      title: 'Филиал',
      dataIndex: 'state',
      render: (x: any) => x.name,
    }] : []),
    {
      title: 'Участники',
      dataIndex: 'usersCount',
    },
    {
      title: 'Дата начала',
      dataIndex: 'start',
      render: val => dayjs(val).format('DD.MM.YYYY'),
    },
    {
      title: 'Дата окончания',
      dataIndex: 'end',
      render: val => dayjs(val).format('DD.MM.YYYY'),
    },
    {
      title: 'Действия',
      dataIndex: 'id',
      render: (id, special) => (
        <div>
          <span onClick={() => handleDelete(id)} style={{ color: '#A40207', cursor: 'pointer' }}>
            <DeleteOutlined style={{ fontSize: 18 }} />
          </span>
          <span onClick={() => handleEdit(special)} style={{ color: '#A40207', cursor: 'pointer', marginLeft: 10 }}>
            <EditOutlined style={{ fontSize: 18 }} />
          </span>
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (val: number) => <span style={{ color: statusList[val].color }}>{statusList[val].val}</span>,
    },
  ];

  const handleSearch = useDebounce((value: string) => {
    if (value.length >= 3 || value === '') {
      setSearchFilter(value.toLowerCase());
      setCurrentPage(1);
    }
  }, 300);

  const onSaveRule = (data: any) => {
    if (data) {
      const index = specials.findIndex(x => x.id === data.id);
      if (index >= 0) {
        const newSpecials = [...specials];
        newSpecials[index] = { ...newSpecials[index], ...data };
        setSpecials(newSpecials);
      } else {
        setSpecials([{...data, state: stateFilter}, ...specials,]);
      }
    }
    setSpecialData(null);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }}>
        <Col span={24} style={{ fontSize: 25 }}>
          Действующие акции
        </Col>
        {!!stateList?.length && (
          <Col flex={1}>
            <Select
              style={{ width: '100%', height: 35 }}
              defaultValue={0}
              options={[
                { label: 'Все филиалы', value: 0 },
                ...stateList.map((x: any) => ({ value: x.id, label: x.name }))
              ]}
              onChange={(value, option) => {
                setStateFilter({ id: value, name: option?.label || '' });
                setCurrentPage(1);
              }}
            />
          </Col>
        )}
        <Col flex={2}>
          <Input
            style={{ height: 35 }}
            onChange={e => handleSearch(e.target.value)}
            placeholder='Поиск по названию (мин 3 символа)'
          />
        </Col>
        {(!!user.isAdmin || user.role === 4) && (
          <Col flex={1}>
            <Button style={{ width: '100%' }} onClick={() => setSpecialData({})}>
              Новая акция <PlusOutlined />
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={specials}
            pagination={{ current: currentPage, pageSize, total: countRows }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <SpecialModal state={stateFilter} specialData={specialData} onSave={onSaveRule} />
    </Spin>
  );
};

export default Specials;
